import axios from "axios"

class Client {
  static async uploadDemoFile(emailAddress, file, onProgress) {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append("email_address", emailAddress)

    return axios.post('/signup/upload.json', formData, {
      headers,
      onUploadProgress: ({ loaded, total, progress }) => {
        if (onProgress) {
          onProgress(progress * 100, loaded, total)
        }
      }
    })
  }
}

export {
  Client
}

export default Client