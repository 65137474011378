<script setup lang="ts">
  import { useDemoStore } from "@clevver/stores/demo.store"
  import { storeToRefs } from 'pinia'

  const demoStore = useDemoStore()
  const { errorMessage } = storeToRefs(demoStore)
</script>

<template>
  <div class="faux-box">
    <div class="flex col">
      <div class="glyph glyph--frown"></div>
      <h1>Error!</h1>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .faux-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;

    text-align: center;
  }

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 60px;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  .glyph {
    display: inline-block;
    margin: 0 auto;
  }
</style>