import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '@clevver/demo.vue'

const Pinia = createPinia()
const app = createApp(App)

app.use(Pinia)
   .mount('#clevver-demo')
