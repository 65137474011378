<script setup>
  import { useDemoStore } from "@clevver/stores/demo.store"
  import { getCurrentInstance } from 'vue'

  import State from "@clevver/enums/state"

  const demoStore = useDemoStore()
  const props = defineProps({
    title: {
      type: String,
      default: "Are you sure?"
    },
    acceptLabel: {
      type: String,
      default: "Okay"
    },
    denyLabel: {
      type: String,
      default: "Cancel"
    }
  })

  function accept() {
    demoStore.clearItems()
    demoStore.setState(State.selection)
  }

  function decline() {
    demoStore.setState(State.approval)
  }
</script>

<template>
  <div class="clvr-confirmation">
    <h1>{{ props.title }}</h1>

    <div class="buttons">
      <button @click.prevent="accept" class="button primary">{{ props.acceptLabel }}</button>
      <button @click.prevent="decline" class="button">{{ props.denyLabel }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .clvr-confirmation {
    min-width: 300px;
    min-height: 300px;
    background: var(--clvr-background);
    padding: 45px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 60px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #1C4389;
  }
</style>

