<script>
  import { $, $$ } from "../selector"

  const CLASS_NAME = "card-flipper"

  export default {
    props: {
      speed: {
        type: Number,
        required: false,
        default: 250
      },
    },

    data() {
      return {
        current: null,
        cards: [],
      }
    },

    computed: {
      classes() {
        return CLASS_NAME
      }
    },

    methods: {
      init() {
        let elements = Array.from(this.$el.children)
        elements.forEach(el => {
          let card = {
            el: el,
            alias: el.dataset.card,
          }
          el.classList.add('card')
          el.style.transitionDuration = `${this.speed}ms`
          el.setAttribute('aria-hidden', true)

          this.cards.push(card)
        })

        if (elements.length && elements.length > 0) {
          this.current = 0
        }

       elements[0].removeAttribute('aria-hidden')
      },

      flip() {
        let nextPage = this.current + 1

        if (nextPage > this.cards.length - 1) {
          nextPage = 0
        }

        this.flipTo(nextPage)
      },

      flipToCard(alias) {
        for (var i = 0; i < this.cards.length; i++) {
          let card = this.cards[i];

          if (card.alias == alias) {
            return this.flipTo(i)
          }
        }
      },

      flipTo(page) {
        let currentPage = this.cards[this.current]
        let nextPage = this.cards[page]

        currentPage.el.style.transform = 'rotateY(90deg)'
        currentPage.el.style.opacity = 0
        currentPage.el.setAttribute('aria-hidden', true)

        const component = this
        this.$emit('cardWillRotateOut', currentPage)
        setTimeout(() => { component.$emit('cardRotatedOut', currentPage) }, this.speed)

        nextPage.el.style.transform = 'rotateY(0deg)'
        nextPage.el.style.opacity = 1
        nextPage.el.removeAttribute('aria-hidden')

        this.$emit('cardWillRotateIn', nextPage)
        setTimeout(() => { component.$emit('cardRotatedIn', nextPage) }, this.speed)

        this.current = page
      },
    },

    created() {
    },

    mounted() {
      this.init()
    }
  }
</script>

<style lang="scss">
  .card-flipper {
    position: relative;
    min-height: 330px;

    .card {
      position: absolute;
      opacity: 0;
      transform: rotateY(90deg);
    }

    .card:first-child {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }

  .card {
    width: 330px;
    min-height: 330px;
    transition: all 250ms linear;
  }
</style>

<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>
