<script setup lang="ts">
  import { $, $$ } from "@clevver/selector"
  import Flipper from "@clevver/components/flipper.vue"

  import { useDemoStore } from "@clevver/stores/demo.store"
  import { State } from "@clevver/enums/state"

  // Stages of the demo
  import FileSelectionStage from "@clevver/components/stages/file-selection-stage.vue"
  import ApprovalStage from "@clevver/components/stages/approval-stage.vue"
  import DeleteConfirmationStage from "@clevver/components/stages/delete-confirmation-stage.vue"
  import UploadStage from "@clevver/components/stages/upload-stage.vue"
  import SuccessStage from "@clevver/components/stages/success-stage.vue"
  import ErrorStage from "@clevver/components/stages/error-stage.vue"

  import {
    ref, watch,
    getCurrentInstance
  } from 'vue'

  const flipperRef = ref<typeof Flipper>()
  const MAX_FILE_SIZE_IN_KB = 10000
  const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_KB * 1024

  const demoStore = useDemoStore()

  const uploadStageRef = ref<InstanceType<typeof UploadStage> | null>(null)

  console.log("demoStore = ", demoStore)

  watch(() => demoStore.state, (newValue, oldValue) => {
    switch(newValue) {
      case State.selection:
        flipperRef.value?.flipToCard('selection')
        break
      case State.approval:
        flipperRef.value?.flipToCard('approval')
        break
      case State.deleteConfirmation:
        flipperRef.value?.flipToCard('confirmDeletion')
        break
      case State.upload:
        flipperRef.value?.flipToCard('upload')
        break
      case State.success:
        flipperRef.value?.flipToCard('success')
        break
      case State.error:
        flipperRef.value?.flipToCard('error')
        break
    }
  })

  function cardRotatedIn(card) {
    switch(card.alias) {
      case 'upload':
        uploadStageRef.value?.init()
        break
    }
  }
</script>

<template>
  <div class="clevver-app">
    <div class="flipper-wrapper">
      <flipper ref="flipperRef" @cardRotatedIn="cardRotatedIn" :speed="250" class="col-12">
        <!-- <div>
          <div class="drop-box">
            <div class="drop-box--inner">
              Demo is currently offline
            </div>
          </div>
        </div> -->
        <file-selection-stage :maxFileSize=MAX_FILE_SIZE data-card="selection" />
        <approval-stage data-card="approval" />
        <delete-confirmation-stage data-card="confirmDeletion" />
        <upload-stage ref="uploadStageRef" data-card="upload" />
        <success-stage data-card="success" />
        <error-stage data-card="error" />
      </flipper>
    </div>
  </div>
</template>

<style lang="scss">
  @use "sass:math";

  .clevver-app {
  }

  .card-flipper {
    // left: 50%;
    // margin-left: -175px;
  }

  .flipper-wrapper {
    width: 330px;
    height: 330px;
    margin: 0 auto;
  }

  /* The box in which everything happens */
  .drop-box {
    position: relative;
    display: inline-block;
    border: 2px dashed rgba(255, 255, 255, 0.7);
    width: 330px;
    height: 330px;
    font-family: 'Montserrat';
    color: #fff;

    &--inner {
      position: absolute;
      inset: 4%;

      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--hover {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      inset: 4%;
      display: none;
      opacity: 0;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      p { font-size: 18px !important; font-weight: 600; }
    }

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 60px;
    }

    p {
      font-weight: 600;
      font-size: 10px;
    }
  }
  .drop-box:hover .drop-box--hover {
    cursor: pointer;
    display: flex;
    opacity: 1;
  }

</style>