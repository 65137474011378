import { defineStore } from 'pinia'
import State from '@clevver/enums/state'
interface IDemoState {
  state: State,
  items: any[],
  emailAddress?: string,
  purchaseId?: string,
  errorMessage?: string
}

const initialState: IDemoState = {
  state: State.selection,
  errorMessage: '',
  items: [],
}

export const useDemoStore = defineStore('demo.store', {
  state: (): IDemoState => ({ ...initialState }),
  getters: {},
  actions: {
    addItem(item: any) {
      this.items.push(item)
    },

    clearItems() {
      // We can't just do this.items = [] because that would
      // just create a new array and leave the old one in memory.
      this.items.length = 0
    },

    setState(state: State) {
      console.log("setting the state to....", state)
      this.state = state
    },

    setEmailAddress(emailAddress: string) {
      this.emailAddress = emailAddress
    },

    setPurchaseId(purchaseId: string) {
      this.purchaseId = purchaseId
    },

    setErrorMessage(errorMessage: string) {
      this.errorMessage = errorMessage
    }
  }
})