<script setup>
  import { $, $$ } from "@clevver/selector"
  import { getCurrentInstance } from "vue";

  import { useDemoStore } from "@clevver/stores/demo.store"
  import { State } from "@clevver/enums/state"

  import DropZone from "@clevver/components/drop-zone.vue"

  const instance = getCurrentInstance()
  const demoStore = useDemoStore()

  const props = defineProps({
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024
    }
  })

  function dragEnter() {
    $("body").classList.add("dragover")
  }

  function dragLeave() {
    $("body").classList.remove("dragover")
  }

  async function fileDropped(data) {
    $("body").classList.remove("dragover")

    let files = data.fileList

    // We're only allowing one file at a time,
    // so let's just grab the first of the batch and
    // ignore the rest
    if (!files.length || files.length < 1) { return }

    let file = files[0]

    if (file.size > props.maxFileSize) { return await alert(`Uploads are currently limited to ${props.maxFileSize } bytes or less.`) }

    // Add the file to the list
    demoStore.addItem(file)

    // Since we're only allowing for a single upload at a time, set the state
    // to approve.
    demoStore.setState(State.approval)
  }

</script>

<template>
  <drop-zone @dzEnter="dragEnter"
             @dzLeave="dragLeave"
             @dzDrop="fileDropped"
             :accept="['image/gif', 'image/jpeg', 'image/png', 'image/svg', 'image/svg+xml']"
             data-card="drop">
    <div class="drop-box">
      <div class="drop-box--inner">
        <div class="glyph--add"></div>
        <h1>Drop File</h1>
        <p>Upload a file to createe your account.</p>
        <p>Up to 2MB for free!</p>
      </div>
    </div>
  </drop-zone>
</template>

<style lang="scss">
  .drop-zone {
    $size: 330px;

    position: relative;
    display: inline-block;
    width: $size;
    height: $size;

    p {
      font-size: 13px !important;
      line-height: 16px;
      text-align: center;
      margin: 0;
    }
  }
</style>