<script setup>
  import { useDemoStore } from "@clevver/stores/demo.store"
  import {
    getCurrentInstance,
    ref,
  } from 'vue'

  import State from "@clevver/enums/state"
  import Client from "@clevver/client"

  const self = getCurrentInstance()
  const demoStore = useDemoStore()
  const props = defineProps({
  })

  const progress = ref(0)

  function init() {
    let items = demoStore.items

    if (!items.length || items.length < 1) { return }

    let item = items[0]

    uploadFile(item)
  }

  async function uploadFile(file) {
    let params = {
      email_address: demoStore.emailAddress,
      file
    }

    const url = `/signup/upload`

    const response = await Client.uploadDemoFile(demoStore.emailAddress, file, (percent, loaded) => {
      progress.value = percent
    }).catch(error => error.response)

    const data = response.data

    if (data.error) {
      console.log(data)
      demoStore.setErrorMessage(data.error)
      demoStore.setState(State.error)

      return
    }

    self.emit("uploaded", file)

    demoStore.setState(State.success)
  }

  defineExpose({
    init
  })
</script>

<template>
  <div class="drop-box">
    <div class="drop-box--inner">
      <progress :value="progress" min="0" max="100" />
    </div>
  </div>
</template>

<style lang="scss">
</style>

