<script setup>
  import { storeToRefs } from 'pinia'
  import { useDemoStore } from "@clevver/stores/demo.store"
  import {
    onMounted,
    watch,
    ref,
    getCurrentInstance
  } from 'vue'

  import State from "@clevver/enums/state"

  import { alert } from "@clevver/dialog"


  const demoStore = useDemoStore()

  const { items } = storeToRefs(demoStore)

  const imagePreview = ref()
  const self = getCurrentInstance()

  function initPreview() {
    let files = items.value

    if (!files.length || files.length < 1) { return }

    let item = files[0]

    imagePreview.value.src = URL.createObjectURL(item)
  }

  async function submitClicked() {
    let termsAccepted = self.refs.terms.checked
    let email = self.refs.emailField.value
    let emailEntered = email.length && email.length > 0 ? true : false

    if (!termsAccepted) {
      return await alert("Please accept the terms and conditions")
    }

    if (!emailEntered) {
     return await alert('Please enter an email address in order to receive your store your image.')
    }

    demoStore.setEmailAddress(email)
    demoStore.setState(State.upload)
  }

  function deleteClicked() {
    demoStore.setState(State.deleteConfirmation)
  }

  watch(demoStore.items, (newValue, oldValue) => {
    initPreview()
  })

</script>

<template>
  <div class="approval">
    <div class="drop-box" data-card="approve">
      <div class="drop-box--inner">
        <img ref="imagePreview" />
      </div>

      <div class="drop-box--hover" @click="deleteClicked">
        <div class="glyph--delete"></div>
        <p>Erase</p>
      </div>
    </div>

    <div class="row">
      <label>
        <input type="checkbox" ref="terms" />

        I agree to <a href="#" data-modal="termsAndConditions">Terms and Conditions</a>
      </label>
    </div>

    <div class="row">
      <div class="pill-input">
        <form @submit.prevent="submitClicked">
          <input type="email" name="email" ref="emailField" aria-label="Email Address" placeholder="email address" />

          <button @click.prevent="submitClicked">Upload</button>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .approval {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .row {
    color: #fff;
    text-align: center;
  }

  a {
    color: #fff;
  }

  label {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 14px;
  }

  .pill-input {
    form {
      display: flex;
      gap: 0;
    }

    button,input {
      font-family: 'Montserrat';
      font-style: normal;
      font-size: 14px;
    }

    input {
      width: 225px;
      height: 52px;
      border: none;
      border-radius: 200px 0px 0px 200px;
      padding-left: 40px;
      padding-right: 10px;
    }

    button {
      border: none;
      border-radius: 0px 200px 200px 0px;
      height: 52px;
      padding: 17px 30px;
      background: var(--clvr-pink);
      color: #fff;
    }
  }

  .clvr-confirmation {
    position: relative;
    inset: 0;
  }
</style>
